import React from 'react'
import './Toast.css'
import { FrMsgSentToast, FrCallMeBackToast } from '../../Languages/French'
import { EngMsgSentToast, EngCallMeBackToast } from '../../Languages/English'
const Toast = ({ language, formType }) => {
  return (
    <div className='Toast'>
        <div className='paper toast_message'>
            <div className='normal_text text_center' id='toast_text'>
                {language === 'FR' && formType === 'Message' && FrMsgSentToast}
                {language === 'ENG' && formType === 'Message' && EngMsgSentToast}
                {language === 'FR' && formType === 'CallMeBack' && FrCallMeBackToast}
                {language === 'ENG' && formType === 'CallMeBack' && EngCallMeBackToast}
            </div>
        </div>
    </div>
  )
}

export default Toast