import * as api from '../api/index';
import { SEND_CALL_ME_BACK, SEND_MESSAGE, SEND_COOKIES,GET_COOKIES  } from '../constants/actionTypes';

export const sendMessage = (formData) => async (dispatch) => {
    try {
        console.log("################")
        const { data } = await api.sendMessageForm(formData)

        dispatch({type : SEND_MESSAGE,data})
        console.log('response')
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

export const sendCallMeBack = (formData) => async (dispatch) => {
    try {
        const { data } = await api.sendCallMeBackForm(formData)

        dispatch({type : SEND_CALL_ME_BACK,data})
        console.log('response')
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

export const sendCookies = (formData) => async (dispatch) => {
    try {
        const { data } = await api.sendCookiesForm(formData)
        dispatch({type : SEND_COOKIES,data})
        console.log('response')
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

export const getCookies = () => async (dispatch) => {
    try {
        const { data } = await api.getCookiesData()
        dispatch({type : GET_COOKIES,payload:data})
        console.log('response')
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}