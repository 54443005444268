import React,{useState} from 'react';
import './Idee.css';
import Idee1 from '../../images/icons_icon_idee_1.svg'
import Idee2 from '../../images/icons_icon_idee_2.svg'
import Idee3 from '../../images/icons_icon_idee_3.svg'
import { FrIdeeTitle, FrIdeePaperTitles, FrIdeePaperText } from '../../Languages/French';
import { EngIdeeTitle, EngIdeePaperTitles, EngIdeePaperText } from '../../Languages/English';
import Ouilink_video from '../../videos/Ouilink-1080.mp4'
import play_button from '../../images/play-button.svg'
import ReactPlayer from 'react-player'


const Idee = ({language, IdeeRef, visible, AnimationTrigger}) => {
    const [playButton,setPlayButton] = useState(true)
    
    const visibility = visible ? 'Idee_section_visible' : ''

    return (
        <section className={`block ${visibility}`} id="Idee_section"  ref={IdeeRef}>
            <div className='main_title main_title_border col-8' id='Idee' >
                    {language === 'FR' ?  FrIdeeTitle : EngIdeeTitle }
            </div>

            <div className='row' id='video_container'>
               {playButton ? 
                    <ReactPlayer className='video' url={Ouilink_video} />               
                :
                    <ReactPlayer className='video' url={Ouilink_video} playing={true}  controls/>
                }
                    <img src={play_button} alt='play' className={playButton ? 'playButton':'hidePlayButton'} onClick={()=> setPlayButton(false)}/>
            </div>
            

            <div className='row row_margin idee_mobile_row'>

                <div className='col-30 paper'>
                    <img src={Idee1} alt="Idee1" className='Idee_img'/>
                    <div className='normal_text center_text'>
                        {language === 'FR' ?  FrIdeePaperTitles[0] : EngIdeePaperTitles[0] }
                    </div>
                    <div className='small_text center_text'>
                        {language === 'FR' ?  FrIdeePaperText[0] : EngIdeePaperText[0] }
                    </div>
                </div>

                <div className='col-30-divider'></div>

                <div className='col-30 paper'>
                    <img src={Idee2} alt="Idee2" className='Idee_img'/>
                    <div className='normal_text center_text'>
                        {language === 'FR' ?  FrIdeePaperTitles[1] : EngIdeePaperTitles[1] }
                    </div>
                    <div className='small_text center_text'>
                        {language === 'FR' ?  FrIdeePaperText[1] : EngIdeePaperText[1] }
                    </div>
                </div>

                <div className='col-30-divider' ref={AnimationTrigger}></div>

                <div className='col-30 paper' >
                    <img src={Idee3} alt="Idee3" className='Idee_img' />
                    <div className='normal_text center_text'>
                        {language === 'FR' ?  FrIdeePaperTitles[2] : EngIdeePaperTitles[2] }
                    </div>
                    <div className='small_text center_text'>
                        {language === 'FR' ?  FrIdeePaperText[2] : EngIdeePaperText[2] }
                    </div>
                </div>

            </div>
        </section>
    );
}



export default Idee;