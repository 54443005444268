import React from 'react';
import './Functioning.css';
import Function1 from '../../images/icons_illus_fonction_1.svg';
import Function2 from '../../images/icons_illus_fonction_2.svg';
import Function3 from '../../images/icons_illus_fonction_3.svg';
import Function4 from '../../images/icons_illus_fonction_4.svg';
import mobileFunction1 from '../../images/mobile_icones process_recup factures.svg'
import mobileFunction2 from '../../images/mobile_icones process_analyse auto.svg'
import mobileFunction3 from '../../images/mobile_icones process_controle activite.svg'
import mobileFunction4 from '../../images/mobile_icones process_certification donnees.svg'
import { FrFunctioningTitle, FrFunctioningPaperTitle, FrFunctioningPaperText } from '../../Languages/French';
import { EngFunctioningTitle, EngFunctioningPaperTitle, EngFunctioningPaperText } from '../../Languages/English';



const Functioning = ({language, visible, showMobileImg, FunctioningRef, AnimationTrigger}) => {

    const visibility = visible ? 'Functioning_section_visible' : ''
    
    return (
        <section className={`block ${visibility}`} id="Functioning_section" ref={FunctioningRef}>
            <div className='main_title main_title_border col-8' id='Functioning'>
                    { language === 'FR' ? FrFunctioningTitle : EngFunctioningTitle }
            </div>

            

            <div className='row row_margin_f' id="functioning_row1">
                <div className='col-6'>
                    <div className='paper paper_block paper_large_screens'>
                        <div className='normal_text text_spacing'>
                            { language === 'FR' ? FrFunctioningPaperTitle[0] : EngFunctioningPaperTitle[0] }
                        </div>
                        <div className='small_text text_spacing'>
                           { language === 'FR' ? FrFunctioningPaperText[0] : EngFunctioningPaperText[0] }
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <img src={showMobileImg ? mobileFunction1 : Function1 } alt='Function1' className='Function_img' id='Function_img1'/> 
                </div>
            </div>

            <div className='row row_margin_f' id="functioning_row2">
                <div className='col-6'>
                    <img src={showMobileImg ? mobileFunction2 : Function2} alt='Function2' className='Function_img'/> 
                </div>
                <div className='col-6'>
                    <div className='paper paper_block paper_large_screens'>
                        <div className='normal_text text_spacing'>
                            { language === 'FR' ? FrFunctioningPaperTitle[1] : EngFunctioningPaperTitle[1] }
                        </div>
                        <div className='small_text text_spacing'>
                            { language === 'FR' ? FrFunctioningPaperText[1] : EngFunctioningPaperText[1] }
                        </div>
                    </div>
                </div>
            </div>

            <div className='row row_margin_f' id="functioning_row3">
                <div className='col-6'>
                    <div className='paper paper_block2 paper_large_screens'>
                        <div className='normal_text text_spacing '>
                            { language === 'FR' ? FrFunctioningPaperTitle[2] : EngFunctioningPaperTitle[2] }
                        </div>
                        <div className='small_text text_spacing'>
                            { language === 'FR' ? FrFunctioningPaperText[2] : EngFunctioningPaperText[2] }
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <img src={showMobileImg ? mobileFunction3 : Function3 } alt='Function3' className='Function_img'/> 
                </div>
            </div>

            <div className='row row_margin_f' id="functioning_row4" ref={AnimationTrigger}>
                <div className='col-6'>
                    <img src={showMobileImg ? mobileFunction4 : Function4 } alt='Function4' className='Function_img4'/> 
                </div>
                <div className='col-6'>
                    <div className='paper paper_block3 paper_large_screens'>
                        <div className='normal_text text_spacing '>
                            { language === 'FR' ? FrFunctioningPaperTitle[3] : EngFunctioningPaperTitle[3] }
                        </div>
                        <div className='small_text text_spacing'>
                            { language === 'FR' ? FrFunctioningPaperText[3] : EngFunctioningPaperText[3] }
                        </div>
                    </div>
                </div>
            </div>


        </section>
    );
}

export default Functioning;