import React from 'react';

const InfoBlock = ({Icon, Title, Text}) => {
    return (
        <div className='info_paper row mobile_info_row'>
                    <div className='col-1 info_icon_img'>
                        <img src={Icon} alt='icon_faq_1' className='faq_icons'/>
                    </div>
                    <div className='col-11 info_text'>
                        <div className='semi_title info_paper_title'>
                            {Title}
                        </div>
                        <div className='small_text infos_text_spacing'>
                            {Text}
                        </div>
                    </div>
        </div>
    );
}

export default InfoBlock;