import React,{useState, useEffect} from 'react'
import './MobileDrawerMenu.css'
import HomeIconOn from '../../images/icons_navbar_accueil_on.svg'
import HomeIconOff from '../../images/icons_navbar_accueil_off.svg'
import IdeeIconOn from '../../images/icons_navbar_idee_on.svg'
import IdeeIconOff from '../../images/icons_navbar_idee_off.svg'
import PartnairsIconOn from '../../images/icons_navbar_partenaire_on.svg'
import PartnairsIconOff from '../../images/icons_navbar_partenaire_off.svg'
import ContactIconOn from '../../images/icons_navbar_contacter_on.svg'
import ContactIconOff from '../../images/icons_navbar_contacter_off.svg'
import FunctioningIconOn from '../../images/icons_navbar_fonct_on.svg'
import FunctioningIconOff from '../../images/icons_navbar_fonct_off.svg'
import InfoIconOn from '../../images/icons_navbar_infos_on.svg'
import InfoIconOff from '../../images/icons_navbar_infos_off.svg'
import LangueFrON from '../../images/icon_langue_fr_on.svg'
import LangueFrOff from '../../images/icon_langue_fr_off.svg'
import LangueEngON from '../../images/icon_langue_eng_on.svg'
import LangueEngOff from '../../images/icon_langue_eng_off.svg'
import Login_btn from '../../images/icons_navbar_login.svg'
// import Signup_btn from '../../images/icons_navbar_signup.svg'



const DrawerMenu = ({handleClick, value, language, handleLanguage, openMenu,handleOpenMenu, CloseMenu, fadeOut}) => {
    const [height,setHeight] = useState(window.innerHeight)
    const menuHeight = {
        height: height + 'px',
      };
    
      const updateWindowHeight = () => {
        const newHeight = window.innerHeight;
        setHeight(newHeight)
      }
      useEffect(() => {
        
        updateWindowHeight()
        
    
      }, [])
      window.addEventListener("resize", updateWindowHeight)

  return (
    <div className="mobileSideNav">
        {openMenu ?
        <div className={fadeOut ? 'FadeOutMobileMenu' : 'MobileMenu'}  onClick={CloseMenu}>
            <div className={fadeOut ? 'FadeOutDisplayedMenu' : 'displayedMenu'} style={menuHeight}>
                <div className='links_container'>
                    <a href="#Home" onClick={() =>handleClick('Home')} className = {value === 'Home' ? 'mobile_links_container_selected' : null}>
                        <img src={value ==='Home' ? HomeIconOff : HomeIconOn } alt='Home' className='MobileLinksIcons'/>
                        <div className={value ==='Home' ? 'mobile_link_title_selected' : 'MobileLinkTitle' }>{ language === 'FR' ? 'Accueil' : 'Home' }</div>
                    </a>
                    <a href="#Idee" onClick={() =>handleClick('Idee')} className = {value === 'Idee' ? 'mobile_links_container_selected' : null}>
                        <img src={value === 'Idee' ? IdeeIconOff : IdeeIconOn } alt='Idee' className='MobileLinksIcons'/>
                        <div className={value ==='Idee' ? 'mobile_link_title_selected' : 'MobileLinkTitle' }>{ language === 'FR' ? 'Notre idée' : 'Our idea' }</div>
                    </a>
                    <a href="#Functioning" onClick={() =>handleClick('Functioning')} className = {value === 'Functioning' ? 'mobile_links_container_selected' : null}>
                        <img src={value === 'Functioning' ? FunctioningIconOff : FunctioningIconOn } alt='Functioning' className='MobileLinksIcons'/>
                        <div className={value ==='Functioning' ? 'mobile_link_title_selected' : 'MobileLinkTitle' }>{ language === 'FR' ? 'Fonctionnement' : 'How it works' } </div>
                    </a>
                    <a href="#Partners" onClick={() =>handleClick('Partners')} className = {value === 'Partners' ? 'mobile_links_container_selected' : null}>
                        <img src={value === 'Partners' ? PartnairsIconOff : PartnairsIconOn} alt='partners' className='MobileLinksIcons'/>
                        <div className={value ==='Partners' ? 'mobile_link_title_selected' : 'MobileLinkTitle' }>{ language === 'FR' ? 'Travailler ensemble' : 'Lets work together'}</div>
                    </a>
                    <a href="#Contact" onClick={() =>handleClick('Contact')} className = {value === 'Contact' ? 'mobile_links_container_selected' : null}>
                        <img src={value === 'Contact' ? ContactIconOff : ContactIconOn } alt='Contact' className='MobileLinksIcons'/>
                        <div className={value ==='Contact' ? 'mobile_link_title_selected' : 'MobileLinkTitle' }>{ language === 'FR' ? 'Nous contacter' : 'Contact us'}</div>
                    </a>
                    <a href="#Infos" onClick={() =>handleClick('Infos')} className = {value === 'Infos' ? 'mobile_links_container_selected' : null}>
                        <img src={value === 'Infos' ? InfoIconOff : InfoIconOn } alt='Infos' className='MobileLinksIcons'/>
                        <div className={value ==='Infos' ? 'mobile_link_title_selected' : 'MobileLinkTitle' }>{language === 'FR' ? 'Renseignements' : 'Informations'}</div>
                    </a>
                </div>
                <div className='mobile_boutons_links'>
                    <a href='#Connect' id='connect_btn'>
                        <img src={Login_btn} alt='Login' className='mobile_btn_icons'/>
                        <div className='mobile_btn_title'>{ language === 'FR' ? 'Se connecter' : 'Login' }</div>
                    </a>
                </div>
                <div className='mobile_last_link'>
                    {language==='FR' ? (
                        <>
                        <button href="" id='Fr_on' onClick={() => handleLanguage('FR')}>
                        <img src={LangueFrON} alt='FrOn' className='mobile_last_link_icon'/>
                        </button>
                        <button href="" id="Eng_off" onClick={() => handleLanguage('ENG')}>
                            <img src={LangueEngOff} alt='EngOff' className='mobile_last_link_icon'/>
                        </button>
                        </>
                    ):(
                        <>
                        <button href="" id='Eng_on' onClick={() => handleLanguage('ENG')}>
                        <img src={LangueEngON} alt='FrOn' className='mobile_last_link_icon'/>
                        </button>
                        <button href="" id="Fr_off" onClick={() => handleLanguage('FR')}>
                            <img src={LangueFrOff} alt='FrOff' className='mobile_last_link_icon'/>
                        </button>
                        </>
                    )}
                    
                </div>
            </div>
        </div>:
        <div className='arrow' onClick={handleOpenMenu}>
            <p>&#9654;</p>
        </div>
        }
    </div>
  )
}

export default DrawerMenu