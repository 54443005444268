import './App.css';
import React,{useState,useEffect,useCallback,useRef, useMemo} from 'react';
import SideNav from './components/DrawerMenu/DrawerMenu.jsx'
import Home from './containers/Home/Home.jsx'
import Idee from './containers/Idee/Idee.jsx'
import Functioning from './containers/Functioning/Functioning';
import Partners from './containers/Partners/Partners'
import Contact from './containers/Contact/Contact';
import Infos from './containers/Infos/Infos';
import Overlay from './containers/Overlay/Overlay';
import MobileSideNav from './components/MobileDrawerMenu/MobileDrawerMenu';
import Toast from './components/Toast/Toast';
import Cookies from 'universal-cookie/es6';





const Message={msg_email:'',profession:'',msg_full_name:'',reason:'',textMsg:''}

const App = () => {


  const cookies = new Cookies()
  const [container,setContainer] = useState('Home')
  const [visible,setVisible] = useState({Idee_section:false,Funct_section:false,Partners_section:false,Contact_section:false,Infos_section:false})
  const [language,setLanguage] = useState( cookies.get('language')!= null ? cookies.get('language') : 'FR')
  const [openCookiesOverlay,setOpenCookiesOverlay] = useState(false)
  const [openMentionsOverlay,setOpenMentionsOverlay] = useState(false)
  const [showMobileNav,setShowMobileNav] = useState(false)
  const [showMobileImg,setShowMobileImg] = useState(false)
  const [openMenu,setOpenMenu] = useState(false)
  const [openToast,setOpenToast] = useState(false)
  const [formType,setFormType] = useState(null)
  const [y, setY] = useState(window.scrollY);
  const [fadeOutMenu,setFadeOutMenu]=useState(false)
  const HomeRef = useRef()
  const IdeeRef = useRef()
  const FunctioningRef = useRef()
  const PartnersRef = useRef()
  const ContactRef = useRef()
  const InfosRef = useRef()
  const IdeeAnimationRef = useRef()
  const FunctioningAnimationRef = useRef()
  const PartnersAnimationRef = useRef()
  const ContactAnimationRef = useRef()
  const ContactAnimationRef2 = useRef()     // for the partner part
  const ContactAnimationRef3 = useRef()     // for the hospitality part
  const InfosAnimationRef = useRef()
  
  const handleOpenMenu = () =>{
      setFadeOutMenu(false)
      setOpenMenu(true)

  }

  const CloseMenu = () =>{
      setFadeOutMenu(true)
      setTimeout(()=>{
        setOpenMenu(false)
      },300)
  }
  
  let touchstartX = 0
  let touchendX = 0
  let touchstartY = 0
  let touchendY = 0

  const swipe = useMemo(()=>{

    document.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
      touchstartY = e.changedTouches[0].screenY
  
    })
    
    document.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      touchendY = e.changedTouches[0].screenY
  
      checkDirection()
    })
  },[])

  
  
  //if you swipe right the menu opens for mobile and tablets
  function checkDirection() {
    if (touchendX - touchstartX > 60 && Math.abs(touchendY-touchstartY )< 20 ){
      handleOpenMenu()
    }
  }

  //this code is responsible for the sections and menu animations while scrolling the website
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if(HomeRef.current!==undefined && IdeeRef.current!==undefined && window.scrollY >= HomeRef.current.offsetTop && window.scrollY < IdeeRef.current.offsetTop) {
        // changes the color and background of the Home button on the navbar
        setContainer('Home')
        //shows the animated Idee section
        if(visible.Idee_section === false && window.scrollY >= IdeeAnimationRef.current.offsetTop ) setVisible({...visible,Idee_section:true})
      }
      if(IdeeRef.current!==undefined && FunctioningRef.current!==undefined && window.scrollY >= IdeeRef.current.offsetTop && window.scrollY < FunctioningRef.current.offsetTop ){
        // changes the color and background of the Idee button on the navbar
        setContainer('Idee')
        //shows the animated Functioning section
        if(visible.Funct_section === false && window.scrollY >= FunctioningAnimationRef.current.offsetTop ) setVisible({...visible,Funct_section:true})
      }
      if(FunctioningRef.current!==undefined && PartnersRef.current!==undefined && window.scrollY >= FunctioningRef.current.offsetTop && window.scrollY < PartnersRef.current.offsetTop ) {
        // changes the color and background of the Functioning button on the navbar
        setContainer('Functioning')
        //shows the animated Partners section
        if(visible.Partners_section === false && window.scrollY >= PartnersAnimationRef.current.offsetTop ) setVisible({...visible,Partners_section:true})
      }
      if(PartnersRef.current!==undefined && ContactRef.current!==undefined && window.scrollY >= PartnersRef.current.offsetTop && window.scrollY < ContactRef.current.offsetTop ) {

        // changes the color and background of the Partner button on the navbar
        setContainer('Partners')

        if(visible.Contact_section === false && ContactAnimationRef.current!==undefined && ContactAnimationRef.current!==null) {
          if(window.scrollY >= ContactAnimationRef.current.offsetTop)
          {
            //shows the animated Contact section
            setVisible({...visible,Contact_section:true})
          }
        }
        if(visible.Contact_section === false && ContactAnimationRef2.current!==undefined && ContactAnimationRef2.current!==null){
          if(window.scrollY >= ContactAnimationRef2.current.offsetTop){
            //shows the animated Contact section
            setVisible({...visible,Contact_section:true})
          }
        } 
        if(visible.Contact_section === false && ContactAnimationRef3.current!==undefined && ContactAnimationRef3.current!==null){
          if(window.scrollY >= ContactAnimationRef3.current.offsetTop){
            //shows the animated Contact section
            setVisible({...visible,Contact_section:true})
          }
        } 
      }
      if(ContactRef.current!==undefined && InfosRef.current!==undefined && window.scrollY >= ContactRef.current.offsetTop && window.scrollY < InfosRef.current.offsetTop ) {
        // changes the color and background of the Idee button on the navbar
        setContainer('Contact')
        //shows the animated Infos section
        if(visible.Infos_section === false && window.scrollY >= PartnersAnimationRef.current.offsetTop ) setVisible({...visible,Infos_section:true})
      }
      if(InfosRef.current.offsetTop!==null && window.scrollY >= InfosRef.current.offsetTop) {
        setContainer('Infos')
      }
      setY(window.scrollY);
    },
    [y]
  );


  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  
  const handleOpenToast = () =>{
    setOpenToast(true)
   
  }

  const handleCloseToast = () =>{
    setOpenToast(false)
   
  }




  const showMobileMenu = () =>{
    if(window.innerWidth < 1024){
      setShowMobileNav(true)
      if(window.innerWidth < 768){
        setShowMobileImg(true)
      }else{
        setShowMobileImg(false)
      }
     
    }else{
      setShowMobileNav(false)
      setShowMobileImg(false)
    }
  }


  useEffect(()=>{
    showMobileMenu()
  },[])


  

  const handleCookieOverlay = () =>{
    setOpenCookiesOverlay(!openCookiesOverlay)
    
  }

  const handleMentionsOverlay = () =>{
    setOpenMentionsOverlay(!openMentionsOverlay)
    
  }

  window.addEventListener('resize',showMobileMenu)

  var hasTouchscreen = 'ontouchstart' in window;


  return (
    <div>
      {
        showMobileNav || hasTouchscreen  ?
        <MobileSideNav handleClick={setContainer} value={container} language={language} handleLanguage={setLanguage} openMenu={openMenu} handleOpenMenu={handleOpenMenu} CloseMenu={CloseMenu} fadeOut={fadeOutMenu} />
        : <SideNav handleClick={setContainer} value={container} language={language} handleLanguage={setLanguage}/>
      }
      <div className='container' onClick={CloseMenu}>
        <Home language={language} handleContainer={setContainer}  handleCookiesOverlay={setOpenCookiesOverlay} HomeRef={HomeRef} AnimationTrigger={IdeeAnimationRef}/>
        <Idee language={language} handleContainer={setContainer} IdeeRef={IdeeRef} visible={visible.Idee_section} AnimationTrigger={FunctioningAnimationRef}/>
        <Functioning language={language} handleContainer={setContainer} container={container} showMobileImg={showMobileImg} FunctioningRef={FunctioningRef} visible={visible.Funct_section} AnimationTrigger={PartnersAnimationRef}/>
        <Partners handleClick={setContainer} Message={Message} language={language} handleContainer={setContainer} PartnersRef={PartnersRef} visible={visible.Partners_section} AnimationTrigger={ContactAnimationRef} AnimationTrigger2={ContactAnimationRef2} AnimationTrigger3={ContactAnimationRef3}/>
        <Contact Message={Message} language={language} handleOpenToast={handleOpenToast} handleCloseToast={handleCloseToast} setFormType={setFormType} handleContainer={setContainer} ContactRef={ContactRef} visible={visible.Contact_section} AnimationTrigger={InfosAnimationRef}/>
        <Infos handleCookiesOverlay={handleCookieOverlay} handleMentionsOverlay={handleMentionsOverlay} language={language} handleContainer={setContainer} InfosRef={InfosRef} visible={visible.Infos_section}/>
        {/* <button onClick={HandleToast}>Toast</button> */}
      </div>
        {openMentionsOverlay && <Overlay handleClose={handleMentionsOverlay} Type="Mentions" language={language} />}
        {openCookiesOverlay && <Overlay handleClose={handleCookieOverlay} Type="Cookies" language={language} />}
        {openToast && <Toast language={language} formType={formType} />}

    </div>
  );
}

export default App;
