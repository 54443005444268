import React from "react"

//HOME Container text

export const EngHomeTitle = 'Reinventing F&B invoice control';
export const EngHomeText = <>*By Professionals, for professionals</>;
export const EngHomeButton = <>Use Ouilink</>;



// Idee Container texts


export const EngIdeeTitle = <>An innovation<br/>in the hospitality sector</>
export const EngIdeePaperTitles = [<>Creating an<br/>artificial intelligence</>,<>Facilitating links<br/>between professionals</>,<>Personalized<br/>dashbord</>]
export const EngIdeePaperText = [<>Our AI algorithms combined with our OCR<br/> capabilities extract and analyze millions of<br/>line items from F&B vendors</>,<>By automating invoices exchanges and<br/> analysis we provide hospitality actors key<br/>data to facilitate their relationship</>,<>Key performance Indicators are matched<br/>with each user needs, be it a single shop or<br/>an international chain.</>]



//Functioning Container texts

export const EngFunctioningTitle = <>The power of an AI to optimize<br/>your invoice management</>
export const EngFunctioningPaperTitle = [<>Retrieval of invoices</>,<>Automatic analysis</>,<>Activity control</>,<>Certification and data access</>]
export const EngFunctioningPaperText = [<>We automatically retrieve invoices from suppliers through the use of<br/>connectors and integration platforms.<br/><br/>Paper documents can be scanned and uploaded using our OCR</>,<>Our AI algorithms will extract data from invoices and understand the<br/>items purchased in order to analyze, rank and compare them.</>,<>Once the items have been classified and identified, the results are either<br/>available on a very intuitive and easy-to-use interface or sent via API to<br/>be integrated into one of our partners solutions.</>,<>The documents received are digitally signed and protected so that they<br/>stick to the original. Cloud access is restricted to your defined contacts.<br/>Data is encrypted and confidential.</>]


// Partners Container texts


export const EngPartnerTitle = <>Partners and customers,<br/> let's move forward together</>
export const EngText = [<>Several software publishers dedicated to the restaurant and hotel sector have chosen the Ouilink OCR to optimize or complement their solution.</>,<>Large restaurant groups, hotel chains or independents wishing to control the activity of their establishment and the performance of their teams, many professionlas have already chosen to benefit from our service and thus increase their profits.</>]

export const EngPartnerBlockBigTitle = ['Software publisher','Accounting department','Supplier']
export const EngPartnerBlockSmallTitle = ['Enhance your solution','Make your integrations easier','Develop your sales']
export const EngPartnerBlockText = [<>Ouilink can integrate with your software suit via<br/>an API or via a white label interface.<br/><br/>We develop solutions that can be adapted to your<br/>customers needs and allow you to differentiate<br/>yourself. </>,<>We could bring a new service to our common <br/>customers with both a global vision and an expert<br/>accountant's adevice, combined with a more<br/> operational and detailed vision thanks to the automatic analysis that is done by the Ouilink<br/>OCR. Contact us for more information!</>,<>The digital relationship with your customers is<br/>bound yo grow. Take advantage of the power of the<br/>Ouilink tool to get to know your customers better<br/>and develop your sales.<br/><br/><br/></>]
export const EngPartnerBlockButtonText = ["Discussing a solution","Create a link","Getting in touch"]

export const EngRestorerBlockBigTitle = ['Controlling your business','Optimizing your business','Managing your network']
export const EngRestorerBlockSmallTitle = [<>1 coffee per day, €49 excl. tax/month*.</>,<>1 cappuccino per day, i.e. €59 excl. tax/ month*.</>,<>Contact us for a quotation</>]
export const EngRestorerBlockTextTitle = ['','All the functionalities of the start module, and :','']
export const EngRestorerBlockList = [[<>Recovering invoices from your suppliers and<br/>sharing with your accountant</>,<>Detailed analysis of your invoices</>,<>Calculate your margin, ratios and monthly operating<br/>profit</>,<>Detection of price increases invoiced by your<br/>suppliers</>,<>Performing audits (average savings of 12% on the<br/>analysed)</>,<>Comparison of your prices with market prices<br/><br/><br/><br/><br/><br/></>],[<>Export of data to accounting software</>,<>Generation, transfer and follow-up of credit note<br/>requests to your suppliers</>,<>Reception of alerts</>,<>Management/associate dashboard</>,<>Management of user rights</>,<>Multi-site reporting and consolidation<br/><br/><br/><br/><br/><br/></>],[<>Customisation of the interface and functionality</>,<>Multi-site management via administation and<br/> comparison tools for your establishments and<br/>suppliers</>,<>Consolidation of your data on a dedicated server</>,<>EFR management<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></>]]
export const EngRestorerBlockListMobile = [[<>Recovering invoices from your suppliers and<br/>sharing with your accountant</>,<>Detailed analysis of your invoices</>,<>Calculate your margin, ratios and monthly operating<br/>profit</>,<>Detection of price increases invoiced by your<br/>suppliers</>,<>Performing audits (average savings of 12% on the<br/>analysed)</>,<>Comparison of your prices with market prices</>],[<>Export of data to accounting software</>,<>Generation, transfer and follow-up of credit note<br/>requests to your suppliers</>,<>Reception of alerts</>,<>Management/associate dashboard</>,<>Management of user rights</>,<>Multi-site reporting and consolidation</>],[<>Customisation of the interface and functionality</>,<>Multi-site management via administation and<br/> comparison tools for your establishments and<br/>suppliers</>,<>Consolidation of your data on a dedicated server</>,<>EFR management</>]]
export const EngRestorerBlockText = [<>Recording of invoices over 2 years<br/><br/>Up to 200 invoices/month<br/><br/>* plus €99 of setup fees. Offer for a 12 month commitment. Offer without commitment at €59/month</>,<>Recording of invoices over 10 years<br/><br/>Up to 300 invoices/month<br/><br/>* plus €99 of setup fees. Offer for a 12 month commitment. Offer without commitment at €69/month<br/><br/></>,<>Recording of invoices over 10 years<br/><br/>Up to 300 invoices/month<br/><br/>Subscription with a 12 month commitment<br/><br/></>]
export const EngRestorerBlockButtonText = ['I want to control','I want to optimise','I want to manage']


// Contact Container texts

export const EngContactTitle = 'Contact us'
export const EngContactpaperTitle = ['Send us a message','Call me back']


// Info Container texts

export const EngInfoTitle = 'Informations'
export const EngPaperTitles = ['Is Ouilink suitable for my business ?','How much can i save ?','how does it work ?','Does it work with all my suppliers ?','I work with a central purchasing office. How does it work ?','What commitment ?','Do i need to buy an equipement ?','Can i use a tablet ?','Is there an additional cost for support and updates ?','How do we manage the confidentiality of your data ?']
export const EngPaperTexts = [<>Ouilink is used in commercial restaurants, collective catering, hotels, bakers, confectioners, caterers, bars or cafés, campsites, food shops or<br/>leisure centers.</>,<>In use, the savings can represent up to 12% of my purchases (average 2021). You will be able to control youur gross margin and the operating<br/> results of your establishment.</>,<>Open an account, Identify your establishment(s), users, suppliers and your cash register software publisher. We'll take care of the rest. We'll<br/>collect the invoices directly and analyse them.</>,<>Yes, automatically with more than millions of French suppliers and with a parameterization for foreign suppliers.</>,<>Very good. Whether it's a central purchasing office or a central referral office, all invoices will be treated in the same way.</>,<>There is no commitment if you choose a monthly plan, or over a 12-month period if you choose an annual plan.</>,<>No, you can use an existing tablet or PC. Paper invoices will have to be scanned (photographed) with a smartphone or scanner. The<br/>dematerialisation of electronic invoices or bills and delivery notes or BL will allow you to avoid having to scan invoices.</>,<>Absolutely! Our applications are fully responsive and adapt to your screen size. You will be able to know in one click the gross margin of your<br/> establishment or your network.</>,<>No, everything is included in your subscription fee. Including the collection of your invoices from your suppliers of row materials, packaging or<br/>cleaning products.</>,<>* To guarantee the confidentiality and security of your data, we respect the regulations of the Commission Nationale de l'informatique et des <br/>Libertés (CNIL), the RGPD and the recommandations of the DGFIP for the signature of electronic invoices.<br/>* All the servers we use are secure (SSL) and the data is encrypted.<br/>* Our servers are located in France.<br/>* All data is anonymised.</>]



// Overlay Container texts

export const EngNoticeTitles = ["Corporate identity","Host identity"]
export const EngNoticeText = [<>SAS Alphalink, a simplified joint stock company, has its registred office at 8 rue Mayencin,<br/>38400 Saint-Martin-d'Hères, France, with a share capital of €77,492.<br/><br/>The company is registred with the RCS of Grenoble under the number 824 276 125,<br/>identified for VAT at FR15 824 276 125. <br/><br/>We can be contacted at contact@ouilink.fr .</>,<>Our sites and applications are hosted on servers located in Europe by Amazon Web Services Inc, PO Box 81226, Seattle, WA 981808-1226 - USA. https://<br/>aws.amazon.com/fr/compliance/eu-data-protection/.</>]
export const EngNoticeButton = 'Close'

export const EngCookieTitle = 'This website use cookies.'
export const EngCookieText = [<>Cookies allow us to personalise content and advertisements, provide social media features and analyse our traffic. We also share information about the use of our site with our social media,<br/>advertising and analytics partners, who may combine this with other information you have provided to them or that they have collected through your use of their services.</>]
export const EngCookieButton = ['Refuse','Allow selection','Allow All']
export const EngCookieSwitchText = ['Required','Preferences','Statistics','Marketing']


//Toast component texts

export const EngMsgSentToast = <>Your message has been sent, we will get back to you by email<br/>as soon as possible.</>
export const EngCallMeBackToast = <>Your request for an appointment has been registred, we will<br/> sent you a date proposal by email.</>