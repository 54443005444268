import { SEND_MESSAGE,SEND_CALL_ME_BACK } from '../constants/actionTypes'


export default (posts = [], action) => {
    switch (action.type) {
      case SEND_MESSAGE:
          console.log('message reducer')
        return null
      case SEND_CALL_ME_BACK:
        console.log('callback reducer')
        return null
      default:
        return null;
    }
  };