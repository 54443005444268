import React,{useState,useEffect} from 'react'
import './styles.css'
import HomeIconOn from '../../images/icons_navbar_accueil_on.svg'
import HomeIconOff from '../../images/icons_navbar_accueil_off.svg'
import IdeeIconOn from '../../images/icons_navbar_idee_on.svg'
import IdeeIconOff from '../../images/icons_navbar_idee_off.svg'
import PartnairsIconOn from '../../images/icons_navbar_partenaire_on.svg'
import PartnairsIconOff from '../../images/icons_navbar_partenaire_off.svg'
import ContactIconOn from '../../images/icons_navbar_contacter_on.svg'
import ContactIconOff from '../../images/icons_navbar_contacter_off.svg'
import FunctioningIconOn from '../../images/icons_navbar_fonct_on.svg'
import FunctioningIconOff from '../../images/icons_navbar_fonct_off.svg'
import InfoIconOn from '../../images/icons_navbar_infos_on.svg'
import InfoIconOff from '../../images/icons_navbar_infos_off.svg'
import LangueFrON from '../../images/icon_langue_fr_on.svg'
import LangueFrOff from '../../images/icon_langue_fr_off.svg'
import LangueEngON from '../../images/icon_langue_eng_on.svg'
import LangueEngOff from '../../images/icon_langue_eng_off.svg'
import Login_btn from '../../images/icons_navbar_login.svg'
// import Signup_btn from '../../images/icons_navbar_signup.svg'
import Cookies from 'universal-cookie/es6'



const DrawerMenu = ({handleClick, value, language, handleLanguage}) => {
    const cookies = new Cookies()
    const d = new Date();
    d.setTime(d.getTime() + (7*24*60*60*1000));
    cookies.set('language',language,{path:'/',expires:d})

    const [height,setHeight] = useState(window.innerHeight)
    const menuHeight = {
            height: height + 'px',
      };
    const defaultHeight = {
        height : 100+'vh'
    }
    
      const updateWindowHeight = () => {
        const newHeight = window.innerHeight;
        setHeight(newHeight)
        console.log(newHeight)
      }
      useEffect(() => {
        
        updateWindowHeight()
        
    
      }, [window.innerHeight])
      window.addEventListener("resize2", updateWindowHeight);
    
  return (
    <div id="mySidenav" className="sidenav" style={window.innerHeight< 1025 ? menuHeight : defaultHeight} onClick="">
        <div className='links_container'>
            <a href="#Home" onClick={() =>handleClick('Home')} className = {value === 'Home' ? 'links_container_selected' : null}>
                <img src={value ==='Home' ? HomeIconOff : HomeIconOn } alt='Home' className='linksIcons'/>
                <div className={value ==='Home' ? 'link_title_selected' : 'LinkTitle' }>{ language === 'FR' ? 'Accueil' : 'Home' }</div>
            </a>
            <a href="#Idee" onClick={() =>handleClick('Idee')} className = {value === 'Idee' ? 'links_container_selected' : null}>
                <img src={value === 'Idee' ? IdeeIconOff : IdeeIconOn } alt='Idee' className='linksIcons'/>
                <div className={value ==='Idee' ? 'link_title_selected' : 'LinkTitle' }>{ language === 'FR' ? 'Notre idée' : 'Our idea' }</div>
            </a>
            <a href="#Functioning" onClick={() =>handleClick('Functioning')} className = {value === 'Functioning' ? 'links_container_selected' : null}>
                <img src={value === 'Functioning' ? FunctioningIconOff : FunctioningIconOn } alt='Functioning' className='linksIcons'/>
                <div className={value ==='Functioning' ? 'link_title_selected' : 'LinkTitle' }>{ language === 'FR' ? 'Fonctionnement' : 'How it works' } </div>
            </a>
            <a href="#Partners" onClick={() =>handleClick('Partners')} className = {value === 'Partners' ? 'links_container_selected' : null}>
                <img src={value === 'Partners' ? PartnairsIconOff : PartnairsIconOn} alt='partners' className='linksIcons'/>
                <div className={value ==='Partners' ? 'link_title_selected' : 'LinkTitle' }>{ language === 'FR' ? 'Travailler ensemble' : 'Lets work together'}</div>
            </a>
            <a href="#Contact" onClick={() =>handleClick('Contact')} className = {value === 'Contact' ? 'links_container_selected' : null}>
                <img src={value === 'Contact' ? ContactIconOff : ContactIconOn } alt='Contact' className='linksIcons'/>
                <div className={value ==='Contact' ? 'link_title_selected' : 'LinkTitle' }>{ language === 'FR' ? 'Nous contacter' : 'Contact us'}</div>
            </a>
            <a href="#Infos" onClick={() =>handleClick('Infos')} className = {value === 'Infos' ? 'links_container_selected' : null}>
                <img src={value === 'Infos' ? InfoIconOff : InfoIconOn } alt='Infos' className='linksIcons'/>
                <div className={value ==='Infos' ? 'link_title_selected' : 'LinkTitle' }>{language === 'FR' ? 'Renseignements' : 'Informations'}</div>
            </a>
        </div>
        
        <div className='boutons_links'>
            <a href='#Connect' id='connect_btn'>
                <img src={Login_btn} alt='Login' className='btn_icons'/>
                <div className='btn_title'>{ language === 'FR' ? 'Se connecter' : 'Login' }</div>
            </a>
            {/* <a href='#Signup' id='signup_btn'>
                <img src={Signup_btn} alt='Signup' className='btn_icons'/>
                <div className='btn_title'>S'inscrire</div>
            </a> */}
        </div>
        <div className='last_link'>
            {language==='FR' ? (
                <>
                <button href="" id='Fr_on' onClick={() => handleLanguage('FR')}>
                <img src={LangueFrON} alt='FrOn' className='last_link_icon'/>
                </button>
                <button href="" id="Eng_off" onClick={() => handleLanguage('ENG')}>
                    <img src={LangueEngOff} alt='EngOff' className='last_link_icon'/>
                </button>
                </>
            ):(
                <>
                <button href="" id='Eng_on' onClick={() => handleLanguage('ENG')}>
                    <img src={LangueEngON} alt='FrOn' className='last_link_icon'/>
                </button>
                <button href="" id="Fr_off" onClick={() => handleLanguage('FR')}>
                    <img src={LangueFrOff} alt='FrOff' className='last_link_icon'/>
                </button>
                </>
            )}
            
        </div>
    </div>
  )
}

export default DrawerMenu