import { SEND_COOKIES,GET_COOKIES } from '../constants/actionTypes'


export default (cookies = [], action) => {
    switch (action.type) {
      case SEND_COOKIES:
          console.log('message reducer')
        return cookies
        case GET_COOKIES:
          console.log('get cookies reducer')
        return action.payload
      default:
        return cookies;
    }
  };