import React,{useState} from 'react';
import Switch from '../../components/Switch/Switch';
import './Overlay.css' 
import { FrNoticeTitles, FrNoticeText, FrNoticeButton, FrCookieTitle, FrCookieText, FrCookieButton, FrCookieSwitchText } from '../../Languages/French';
import { EngNoticeTitles, EngNoticeText, EngNoticeButton, EngCookieTitle, EngCookieText, EngCookieButton, EngCookieSwitchText } from '../../Languages/English';
import Cookies from 'universal-cookie/es6';

const Overlay = ({handleClose,Type, language}) => {
   
    const cookies = new Cookies()
    const [necessaryChk,setNecessaryChk] = useState(cookies.get('cookiesParameters')!=null ? cookies.get('cookiesParameters').necessaryCookies : true)
    const [preferenceChk,setPreferenceChk] = useState(cookies.get('cookiesParameters')!=null ? cookies.get('cookiesParameters').preferenceCookies : true)
    const [statisticChk,setStatisticChk] = useState(cookies.get('cookiesParameters')!=null ? cookies.get('cookiesParameters').statisticCookies : true)
    const [marketingChk,setMarketingChk] = useState(cookies.get('cookiesParameters')!=null ? cookies.get('cookiesParameters').marketingCookies : true)
    const d = new Date();
    d.setTime(d.getTime() + (7*24*60*60*1000));
    

    const handlePreferenceChk = () =>{
        setPreferenceChk(!preferenceChk)
    }

    const handleStatisticChk = () =>{
        setStatisticChk(!statisticChk)
    }

    const handleMarketingChk = () =>{
        setMarketingChk(!marketingChk)
    }

    const handleFormSubmit = (e) =>{
        e.preventDefault()
        console.log('necessary : '+necessaryChk+'preference :'+preferenceChk+'statistic: '+statisticChk+' marketing :'+marketingChk)
        const cookiesParameters = {necessaryCookies:necessaryChk,preferenceCookies:preferenceChk,statisticCookies:statisticChk,marketingCookies:marketingChk}
        cookies.set('cookiesParameters',cookiesParameters,{path:'/',expires:d})
        handleClose()
    }

    const handleAcceptAll = (e) =>{
        e.preventDefault()
        // setMarketingChk(true)
        setNecessaryChk(true)
        // setPreferenceChk(true)
        // setStatisticChk(true)       
        const cookiesParameters = {necessaryCookies:true,preferenceCookies:true,statisticCookies:true,marketingCookies:true}
        console.log(cookiesParameters)
        cookies.set('cookiesParameters',cookiesParameters,{path:'/',expires:d})
        handleClose()
        
    }
    
    return (
        <div className='overlay'>
            <div className='overlay_empty_zone' onClick={Type==='Mentions'?handleClose:null}></div>
            <div className='overlay_details_zone'>
                <div className='overlay_container'>
                    {Type==='Mentions' && 
                        <>
                            <div className='row overlay_row'>
                                <div className='col-6'>
                                    <div className='overlay_title'>{language === 'FR' ? FrNoticeTitles[0] : EngNoticeTitles[0] }</div>
                                    <div className='overlay_text'>
                                        {language === 'FR' ? FrNoticeText[0] : EngNoticeText[0] }
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='overlay_title'>{language === 'FR' ? FrNoticeTitles[1] : EngNoticeTitles[1] }</div>
                                    <div className='overlay_text'>
                                        {language === 'FR' ? FrNoticeText[1] : EngNoticeText[1] }
                                    </div>
                                </div>
                            </div>
                            <div className='row align_overlay_link'>
                                <button name="restorer" className='overlay_btn' onClick={handleClose}>{language === 'FR' ? FrNoticeButton : EngNoticeButton }</button>
                            </div>
                            <div className='row mobile_align_overlay_link'>
                                <button name="restorer" className='overlay_btn' onClick={handleClose}>{language === 'FR' ? FrNoticeButton : EngNoticeButton }</button>
                            </div>
                        </>
                    }

                    {Type==='Cookies' && 
                        <>
                            <div className='row overlay_row'>
                                <div className='col-12'>
                                    <div className='overlay_title2'> { language === 'FR' ? FrCookieTitle : EngCookieTitle } </div>
                                    <div className='overlay_text'>
                                        { language === 'FR' ? FrCookieText : EngCookieText }
                                    </div>
                                </div>
                            </div>
                            <form className='cookie_form' onSubmit={handleFormSubmit}>
                                <div className='row switch_row overlay_title2' >
                                    { language === 'FR' ? FrCookieSwitchText[0] : EngCookieSwitchText[0] } <Switch checked={necessaryChk} /> { language === 'FR' ? FrCookieSwitchText[1] : EngCookieSwitchText[1] } <Switch checked={preferenceChk} handleChange={handlePreferenceChk}/> { language === 'FR' ? FrCookieSwitchText[2] : EngCookieSwitchText[2] } <Switch checked={statisticChk} handleChange={handleStatisticChk}/> { language === 'FR' ? FrCookieSwitchText[3] : EngCookieSwitchText[3] } <Switch checked={marketingChk} handleChange={handleMarketingChk}/>
                                </div>
                                <div className='row mobile_switch_row overlay_title2' >
                                    { language === 'FR' ? FrCookieSwitchText[0] : EngCookieSwitchText[0] } <Switch checked={necessaryChk} /> { language === 'FR' ? FrCookieSwitchText[1] : EngCookieSwitchText[1] } <Switch checked={preferenceChk} handleChange={handlePreferenceChk}/> 
                                </div>
                                <div className='row mobile_switch_row overlay_title2' >
                                    { language === 'FR' ? FrCookieSwitchText[2] : EngCookieSwitchText[2] } <Switch checked={statisticChk} handleChange={handleStatisticChk}/> { language === 'FR' ? FrCookieSwitchText[3] : EngCookieSwitchText[3] } <Switch checked={marketingChk} handleChange={handleMarketingChk}/>
                                </div>
                                <div className='row align_overlay_link'>
                                    <button name="cancel" className='overlay_btn2' onClick={handleClose}>{ language === 'FR' ? FrCookieButton[0] : EngCookieButton[0] }</button>
                                    <button type="submit" name="accept" className='overlay_btn2' >{ language === 'FR' ? FrCookieButton[1] : EngCookieButton[1] }</button>
                                    <button  name="acceptAll" className='overlay_btn3' onClick={handleAcceptAll}>{ language === 'FR' ? FrCookieButton[2] : EngCookieButton[2] }</button>
                                </div>
                                <div className='row mobile_align_overlay_link'>
                                    <button name="cancel_mobile" className='overlay_btn2 col-7' onClick={handleClose}>{ language === 'FR' ? FrCookieButton[0] : EngCookieButton[0] }</button>
                                    <button type="submit" name="acceptMobile" className='overlay_btn2 col-5' >{ language === 'FR' ? FrCookieButton[1] : EngCookieButton[1] }</button>
                                </div>
                                <div className='row mobile_align_overlay_link'>
                                    <button type="submit" name="acceptAllMobile" className='overlay_btn3' onClick={handleAcceptAll}>{ language === 'FR' ? FrCookieButton[2] : EngCookieButton[2] }</button>
                                </div>


                            </form>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Overlay;