import React,{useState} from 'react'
import './Contact.css'
import { FrContactTitle, FrContactpaperTitle } from '../../Languages/French'
import { EngContactTitle, EngContactpaperTitle } from '../../Languages/English'
import { useDispatch } from 'react-redux'
import { sendMessage, sendCallMeBack } from '../../actions/Forms';



const CallMeBack={subject:'',full_name:'',contact_email:'',phone:'',schedule:''}

const Contact = ({Message,language, handleOpenToast,handleCloseToast, setFormType, ContactRef, AnimationTrigger, visible}) => {
const [callMeBackData,setCallMeBackData] = useState(CallMeBack)
const [msgData,setMsgData] = useState(Message)
const dispatch = useDispatch()


const handleFormSubmit = (e) =>{
    e.preventDefault()
    setFormType('Message')
    handleOpenToast()
    setTimeout(handleCloseToast,4000)
    dispatch(sendMessage(msgData))
    setMsgData(Message)
    // console.log("------------")
    // console.log(msgData)

}

const handleForm2Submit = (e) =>{
    e.preventDefault()
    setFormType('CallMeBack')
    handleOpenToast()
    setTimeout(handleCloseToast,4000)
    dispatch(sendCallMeBack(callMeBackData))
    setCallMeBackData(CallMeBack)
}

const visibility = visible ? 'Contact_section_visible' : ''

 
  return (
        
        <section className={`block ${visibility}`} id='Contact_section'  ref={ContactRef}>
            <div className='main_title main_title_border' id='Contact' >
                        { language === 'FR' ? FrContactTitle : EngContactTitle }
            </div>

            <div className='row contact_row_margin mobile_contact_row'>
                <div className='col-7 paper'>
                    <div className='semi_title '>
                        { language === 'FR' ? FrContactpaperTitle[0] : EngContactpaperTitle[0] }
                    </div>

                    <form className='contact_form' onSubmit={handleFormSubmit}>

                        <div className='row mobile_contact_form_row'>
                            <div className='col-7'>
                                <label className='form_label' for="msg_email">{ language === 'FR' ? 'Votre mail' : 'Your email' } </label>
                                <input type="text" name='msg_email' id='msg_email' value={msgData.msg_email} onChange={(e) => setMsgData({...msgData,msg_email: e.target.value})} placeholder={language === 'FR' ? 'Pour que nous puissions vous contacter' : 'So that we can contact you'} className='form_input' required/>
                            </div>
                            <div className='col-4-divider'></div>
                            <div className='col-37'>
                                <label className='form_label' for="profession">{ language === 'FR' ? 'Votre profession' : 'Your profession' }</label>
                                <select id="profession" name="profession" value={msgData.profession} onChange={(e) => setMsgData({...msgData,profession: e.target.value})} className='form_input msg_select select_appearance' required>
                                    <option value="" disabled selected hidden>{ language === 'FR' ? 'Choisissez dans la liste déroulante' : 'Choose from the dropdown list'}</option>
                                    <option value="CHR_owner"> { language === 'FR' ? "Propriétaire d'un établissement CHR" : "Owner of a CHR establishment"}</option>
                                    <option value="Software_editor"> { language === 'FR' ? 'Editeur de logiciel' : 'Software publisher'}</option>
                                    <option value="Accountant"> { language === 'FR' ? 'Comptable' : 'Accountant'} </option>
                                    <option value="supplier"> { language === 'FR' ? 'Distributeur' : 'Supplier'} </option>
                                    <option value="other_profession"> { language === 'FR' ? 'Autre' : 'Other'} </option>
                                </select>
                            </div>
                        </div>

                        <div className='row mobile_contact_form_row'>
                            <div className='col-7'>
                                <label className='form_label' htmlFor='' for="msg_full_name"> { language === 'FR' ? 'Vous vous appelez' : 'Your name is'} </label>
                                <input type="text" name='msg_full_name' value={msgData.msg_full_name} onChange={(e) => setMsgData({...msgData,msg_full_name: e.target.value})} id='msg_full_name' placeholder={language ==='FR' ? 'Prénom, Nom' : 'First name, last name'} className='form_input' required/>
                            </div>
                            <div className='col-4-divider'></div>
                            <div className='col-37'>
                                <label className='form_label' for="reason"> { language === 'FR' ? 'Vous voulez' : 'You want to'} </label>
                                <select id="reason" name="reason" value={msgData.reason} onChange={(e) => setMsgData({...msgData,reason: e.target.value})} className='form_input msg_select select_appearance' required>
                                    <option value="" disabled selected hidden> { language === 'FR' ? 'Choisissez dans la liste déroulante' : 'Choose from the dropdown list'} </option>
                                    <option value="master">{ language === 'FR' ? 'Maitriser' : 'Control'}</option>
                                    <option value="optimise">{ language === 'FR' ? 'Optimiser' : 'Optimize'}</option>
                                    <option value="manage">{ language === 'FR' ? 'Gérer' : 'Manage'}</option>
                                </select>
                            </div>

                        </div>

                        <textarea className='form_input form_textarea' name='textMsg' value={msgData.textMsg} onChange={(e) => setMsgData({...msgData,textMsg: e.target.value})} placeholder={ language === 'FR' ? 'Ecrivez votre message ici' : 'Write your message here'}></textarea>

                        <div className='row'>
                            <button className='contact_btn col-12' id='send_msg' type='submit'>{ language === 'FR' ? 'Envoyer le message' : 'Write your message here'}</button>
                        </div>

                    </form>




                </div>
                <div className='col-4-divider'></div>
                <div className='col-37 paper'>
                    <div className='semi_title'>
                        { language === 'FR' ? FrContactpaperTitle[1] : EngContactpaperTitle[1] }
                        
                    </div>

                    <form className='contact_form' onSubmit={handleForm2Submit}>

                        <label className='form_label' for="subject">{ language === 'FR' ? 'Au sujet de' : 'About'}</label>

                        <select id="subject" name="subject" className='form_input select_appearance' value={callMeBackData.subject} onChange={(e)=> setCallMeBackData({...callMeBackData,subject:e.target.value})} required>
                            <option value="" disabled selected hidden>{ language === 'FR' ? 'Choisissez dans la liste déroulante' : 'Choose from the dropdown list'}</option>
                            <option value="visio">{ language === 'FR' ? 'Demande de démo en visioconférence' : 'Request a video conference demo'}</option>
                            <option value="more_info">{ language === 'FR' ? 'Informations supplémentaires' : 'More information'}</option>
                            <option value="Api_links">{ language === 'FR' ? 'Lien API' : 'API Link'}</option>
                            <option value="other_subject">{ language === 'FR' ? 'Autre' : 'Other'}</option>
                        </select>

                        <label className='form_label' for="full_name">{ language === 'FR' ? 'Vous vous appelez' : 'Your name is'}</label>
                        <input type="text" name='full_name' id='full_name' placeholder={ language === 'FR' ? 'Prénom, nom' : 'First name, last name'} className='form_input' value={callMeBackData.full_name} onChange={(e)=> setCallMeBackData({...callMeBackData,full_name:e.target.value})} required/>

                        <label className='form_label' for="contact_email" ref={AnimationTrigger}>{ language === 'FR' ? 'Votre mail' : 'Your email'}</label>
                        <input type="text" name='contact_email' id='contact_email' placeholder={ language === 'FR' ? 'Pour que nous puissions vous contacter' : 'So that we can contact you'} className='form_input' value={callMeBackData.contact_email} onChange={(e)=> setCallMeBackData({...callMeBackData,contact_email:e.target.value})} required/>

                        <label className='form_label' for="phone">{ language === 'FR' ? 'Votre téléphone' : 'Your telephone'}</label>
                        <input type="tel" name='phone' id='phone' placeholder={ language === 'FR' ? 'Si vous optez pour un RDV téléphonique' : 'If you opt for a telephone appointment'} className='form_input' value={callMeBackData.phone} onChange={(e)=> setCallMeBackData({...callMeBackData,phone:e.target.value})}/>
                        
                        <label className='form_label' for="schedule" required>{ language === 'FR' ? 'Un horaire pour vous contacter' : 'The best moment to contact you'}</label>
                        <select id="schedule" name="schedule" className='form_input select_appearance' value={callMeBackData.schedule} onChange={(e)=> setCallMeBackData({...callMeBackData,schedule:e.target.value})}>
                            <option value="" disabled selected hidden>{ language === 'FR' ? 'Choisissez dans la liste déroulante' : 'Choose from the dropdown list'}</option>
                            <option value="from8to10">{ language === 'FR' ? 'Premières heures 8h-10h' : 'Early hours from 8am-10am'}</option>
                            <option value="from10to12">{ language === 'FR' ? 'Matinée 10h-12h' : 'Morning 10am-12pm'}</option>
                            <option value="from13to15">{ language === 'FR' ? 'Après midi 13h-15h' : 'Afternoon 1pm-3pm'}</option>
                            <option value="from15to17">{ language === 'FR' ? 'Dernier créneau 15h-17h' : 'Last slot 3pm-5pm'}</option>
                        </select>

                        <div className='contact_form2_btn row'>
                            <button className='contact_btn col-12' type='submit'>{ language === 'FR' ? 'Envoyer la demande' : 'Send this request'}</button>
                        </div>

                    </form>
                </div>
            </div>

        </section>
  )
}

export default Contact