import React,{useEffect, useState} from 'react';
import './Partners.css';
import EasylisLogo from '../../images/logos partenaires/logo_logo_easilys.png';
import AwsLogo from '../../images/logos partenaires/logo_logo_aws.png';
import BpiLogo from '../../images/logos partenaires/logo_logo_bpi.png';
import CicLogo from '../../images/logos partenaires/logo_logo_cic.png';
import LftLogo from '../../images/logos partenaires/logo_logo_lft.png';
import LadditionLogo from '../../images/logos partenaires/logo_logo_laddition.png';
import MinalogicLogo from '../../images/logos partenaires/logo_logo_minalogic.png';
import IconComa1 from '../../images/icon_coma1_mobile.svg';
import IconComa2 from '../../images/icon_coma2_mobile.svg';
import PartnerBlock from '../../components/PartnerBlock/PartnerBlock';
import RestorerBlock from '../../components/RestorerBlock/RestorerBlock';
import { FrPartnerTitle, FrText, FrPartnerBlockBigTitle, FrPartnerBlockSmallTitle, FrPartnerBlockText, FrPartnerBlockButtonText, FrRestorerBlockBigTitle, FrRestorerBlockSmallTitle, FrRestorerBlockTextTitle, FrRestorerBlockList, FrRestorerBlockListMobile, FrRestorerBlockText, FrRestorerBlockButtonText } from '../../Languages/French';
import { EngPartnerTitle, EngText, EngPartnerBlockBigTitle, EngPartnerBlockSmallTitle, EngPartnerBlockText, EngPartnerBlockButtonText, EngRestorerBlockBigTitle, EngRestorerBlockSmallTitle, EngRestorerBlockTextTitle, EngRestorerBlockList, EngRestorerBlockListMobile, EngRestorerBlockText, EngRestorerBlockButtonText } from '../../Languages/English';



const Partners = ({handleClick,Message,language, visible, PartnersRef, AnimationTrigger, AnimationTrigger2, AnimationTrigger3}) => {
    const [partnership,setPartnership] = useState('main')

    const [FrRestorerTextList,setFrRestorerTextList]=useState(FrRestorerBlockList)
    const [EngRestorerTextList,setEngRestorerTextList]=useState(EngRestorerBlockList)
    
    const visibility = visible ? 'Partners_section_visible' : ''

    const handleRestorerTextList = () =>{
        if(window.innerWidth<768){
            setFrRestorerTextList(FrRestorerBlockListMobile)
            setEngRestorerTextList(EngRestorerBlockListMobile)

        }else{
            setFrRestorerTextList(FrRestorerBlockList)
            setEngRestorerTextList(EngRestorerBlockList)

        }
    }

    useEffect(()=>{
        handleRestorerTextList()
    },[window.innerWidth])

    window.addEventListener('textResise',handleRestorerTextList)

    return (
        <section className={`block ${visibility}`} id='Partners_section' ref={PartnersRef}>
            <div className='main_title main_title_border' id="Partners" >
                { language === 'FR' ? FrPartnerTitle : EngPartnerTitle }
            </div>

            {   partnership==='main' && 

                <div className='block row_margin'>

                    <div className='semi_title text_center'>
                            {language === 'FR' ? <>Envie d'avoir un aperçu de nos <b>prestations</b> et <b>tarifs</b> ? Vous êtes :</>  : <>Want to know more about our <b>services</b> and <b>fees</b> ? You are :</>}
                    </div>

                    <div className='row partner_types'>
                        <div className='paper2 cursor' onClick={ () => setPartnership('partner')}>
                            <div className='normal_text partner_paper_text' >
                                {language === 'FR' ? 'Partenaire curieux' : 'Curious partner'}
                            </div>
                        </div>

                        <div className='paper2 cursor' onClick={() => setPartnership('restorer')}>
                            <div className='normal_text partner_paper_text' >
                                {language === 'FR' ? 'Restaurateur intéressé' : 'Interested restorer'}
                            </div>
                        </div>
                    </div>

                    <div className='partners paper3' ref={AnimationTrigger}>
                        <div className='row partner_logos'>
                            <img src={EasylisLogo} alt="Easylis" className='partner_img' id='EasylisLogo'/>
                            <img src={AwsLogo} alt="Aws" className='partner_img' id='AwsLogo'/>
                            <img src={BpiLogo} alt="Bpi" className='partner_img' id='BpiLogo'/>
                            <img src={CicLogo} alt="Cic" className='partner_img' id='CicLogo'/>
                        </div>

                        <div className='row partner_logos'>
                            <img src={LftLogo} alt="Lft" className='partner_img' id='LftLogo'/>
                            <img src={LadditionLogo} alt="Laddition" className='partner_img' id='LadditionLogo'/>
                            <img src={MinalogicLogo} alt="Minalogic" className='partner_img' id='MinalogicLogo'/>
                        </div>
                        
                        {/* Mobile section */}

                        <div className='row mobile_partner_logos'>
                            <img src={EasylisLogo} alt="Easylis" className='partner_img' id='EasylisLogo'/>
                            <img src={AwsLogo} alt="Aws" className='partner_img' id='AwsLogo'/>
                        </div>
                        
                        <div className='row mobile_partner_logos'>
                            <img src={BpiLogo} alt="Bpi" className='partner_img' id='BpiLogo'/>
                            <img src={CicLogo} alt="Cic" className='partner_img' id='CicLogo'/>
                        </div>

                        <div className='row mobile_partner_logos'>
                            <img src={LftLogo} alt="Lft" className='partner_img' id='LftLogo'/>
                            <img src={MinalogicLogo} alt="Minalogic" className='partner_img' id='MinalogicLogo'/>
                        </div>
                        <div className='row mobile_partner_logos'>
                            <img src={LadditionLogo} alt="Laddition" className='partner_img' id='LadditionLogo'/>
                        </div>



                    </div>
                </div>
            }

            {   partnership === 'partner' &&
                <div className='block2'>
                    <div className='paper row'>
                        <div className='col-1'>
                            <img src={IconComa1} alt="coma1" className="comas" id='coma1'/>
                        </div>
                        <div className='col-10 partner_description text_center'>
                                {language === 'FR' ? FrText[0] : EngText[0]}
                        </div>
                        <div className='col-1'>
                            <img src={IconComa2} alt="coma2" className="comas" id="coma2"/>
                        </div>
                    </div>

                    <div className='row partner_row_margin mobile_partner_row'>
                        <div className='col-30'>
                            <PartnerBlock BigTitle={language === 'FR' ? FrPartnerBlockBigTitle[0] : EngPartnerBlockBigTitle[0]} SmallTitle={language === 'FR' ? FrPartnerBlockSmallTitle[0] : EngPartnerBlockSmallTitle[0]} Text={language === 'FR' ? FrPartnerBlockText[0] : EngPartnerBlockText[0]} Button_text={language === 'FR' ? FrPartnerBlockButtonText[0] : EngPartnerBlockButtonText[0]} handleContactClick={handleClick} Link="#Contact" />
                        </div>

                        <div className='col-30-divider'></div>

                        <div className='col-30 '>
                            <PartnerBlock BigTitle={language === 'FR' ? FrPartnerBlockBigTitle[1] : EngPartnerBlockBigTitle[1]} SmallTitle={language === 'FR' ? FrPartnerBlockSmallTitle[1] : EngPartnerBlockSmallTitle[1]} Text={language === 'FR' ? FrPartnerBlockText[1] : EngPartnerBlockText[1]} Button_text={language === 'FR' ? FrPartnerBlockButtonText[1] : EngPartnerBlockButtonText[1]} handleContactClick={handleClick} Link="#Contact" />                 
                        </div>

                        <div className='col-30-divider'></div>

                        <div className='col-30 ' ref={AnimationTrigger2}>
                            <PartnerBlock BigTitle={language === 'FR' ? FrPartnerBlockBigTitle[2] : EngPartnerBlockBigTitle[2]} SmallTitle={language === 'FR' ? FrPartnerBlockSmallTitle[2] : EngPartnerBlockSmallTitle[2]} Text={language === 'FR' ? FrPartnerBlockText[2] : EngPartnerBlockText[2]} Button_text={language === 'FR' ? FrPartnerBlockButtonText[2] : EngPartnerBlockButtonText[2]} handleContactClick={handleClick} Link="#Contact" />
                        </div>
                    </div>

                    <div className='row align_link'>
                        <button name="restorer" className='link' onClick={() => setPartnership('restorer')}>{language === 'FR' ? 'Voir la partie restaurateur' :'Show the hospitality side'}</button>
                    </div>
                </div>
            }

            {   partnership === 'restorer' &&
                <div className='block2'>
                    <div className='paper row'>
                        <div className='col-1'>
                            <img src={IconComa1} alt="coma1" className="comas" id='coma1'/>
                        </div>
                        <div className='col-10 restorer_description text_center'>
                                {language === 'FR' ? FrText[1] : EngText[1] }
                        </div>
                        <div className='col-1'>
                            <img src={IconComa2} alt="coma2" className="comas" id="coma2"/>
                        </div>
                    </div>

                    <div className='row partner_row_margin mobile_partner_row'>
                        <div className='col-30'>
                            <RestorerBlock BigTitle={language === 'FR' ? FrRestorerBlockBigTitle[0] : EngRestorerBlockBigTitle[0]} SmallTitle={language === 'FR' ? FrRestorerBlockSmallTitle[0] : EngRestorerBlockSmallTitle[0]} TextTitle={language === 'FR' ? FrRestorerBlockTextTitle[0] : EngRestorerBlockTextTitle[0]} List={language === 'FR' ? FrRestorerTextList[0] : EngRestorerTextList[0]} Text={language === 'FR' ? FrRestorerBlockText[0] : EngRestorerBlockText[0]} ButtonText={language === 'FR' ? FrRestorerBlockButtonText[0] : EngRestorerBlockButtonText[0]} handleContactClick={handleClick} Link="#Contact" Message={Message} Reason="master" id='master_btn'/>
                        </div>

                        <div className='col-30-divider'></div>

                        <div className='col-30 '>
                            <RestorerBlock BigTitle={language === 'FR' ? FrRestorerBlockBigTitle[1] : EngRestorerBlockBigTitle[1]} SmallTitle={language === 'FR' ? FrRestorerBlockSmallTitle[1] : EngRestorerBlockSmallTitle[1]} TextTitle={language === 'FR' ? FrRestorerBlockTextTitle[1] : EngRestorerBlockTextTitle[1]} List={language === 'FR' ? FrRestorerTextList[1] : EngRestorerTextList[1]} Text={language === 'FR' ? FrRestorerBlockText[1] : EngRestorerBlockText[1]} ButtonText={language === 'FR' ? FrRestorerBlockButtonText[1] : EngRestorerBlockButtonText[1]} ShowTitle={true} handleContactClick={handleClick} Link="#Contact" Message={Message} Reason="optimise" id='optimise_btn'/>   
                        </div>

                        <div className='col-30-divider'></div>

                        <div className='col-30' ref={AnimationTrigger3}>
                            <RestorerBlock BigTitle={language === 'FR' ? FrRestorerBlockBigTitle[2] : EngRestorerBlockBigTitle[2]} SmallTitle={language === 'FR' ? FrRestorerBlockSmallTitle[2] : EngRestorerBlockSmallTitle[2]} TextTitle={language === 'FR' ? FrRestorerBlockTextTitle[2] : EngRestorerBlockTextTitle[2]} List={language === 'FR' ? FrRestorerTextList[2] : EngRestorerTextList[2]} Text={language === 'FR' ? FrRestorerBlockText[2] : EngRestorerBlockText[2]} ButtonText={language === 'FR' ? FrRestorerBlockButtonText[2] : EngRestorerBlockButtonText[2]} handleContactClick={handleClick} Link="#Contact" Message={Message} Reason="manage" id='manage_btn'/>  
                        </div>
                    </div>

                    <div className='row align_link'>
                        <button name="restorer" className='link' onClick={() => setPartnership('partner')}>{language === 'FR' ? 'Voir la partie partenaire' : 'Show the partner side'}</button>
                    </div>
                </div>
            }


        </section>
    );
}




export default Partners;