import React from 'react';
import './Switch.css';


const Switch = ({checked,handleChange}) => {
    return (
        <div className='switch_margin'>
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={handleChange} />
                <span className="slider"></span>
            </label>
        </div>
    );
}

export default Switch;