import React from 'react';
import icon_faq_1 from '../../images/icons_icon_faq_1.svg'
import icon_faq_2 from '../../images/icons_icon_faq_2.svg'
import icon_faq_3 from '../../images/icons_icon_faq_3.svg'
import icon_faq_4 from '../../images/icons_icon_faq_4.svg'
import icon_faq_5 from '../../images/icons_icon_faq_5.svg'
import icon_faq_6 from '../../images/icons_icon_faq_6.svg'
import icon_faq_7 from '../../images/icons_icon_faq_7.svg'
import icon_faq_8 from '../../images/icons_icon_faq_8.svg'
import icon_faq_9 from '../../images/icons_icon_faq_9.svg'
import icon_faq_10 from '../../images/icons_icon_faq_10.svg'
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import './Infos.css'
import { FrInfoTitle, FrPaperTitles, FrPaperTexts } from '../../Languages/French';
import { EngInfoTitle, EngPaperTitles, EngPaperTexts } from '../../Languages/English';



const Infos = ({handleCookiesOverlay,handleMentionsOverlay,language, visible, InfosRef}) => {

    const visibility = visible ? 'Infos_section_visible' : ''

    return (
        <section className={`block ${visibility}`} id='Infos_section' ref={InfosRef}>
            <div className='main_title main_title_border' id='Infos'>
                    {language === 'FR' ? FrInfoTitle : EngInfoTitle }
            </div>

            <div className='infos_row_margin'>
                <InfoBlock Icon={icon_faq_1} Title={language === 'FR' ? FrPaperTitles[0] : EngPaperTitles[0] } Text={language === 'FR' ? FrPaperTexts[0] : EngPaperTexts[0] } />
                <InfoBlock Icon={icon_faq_2} Title={language === 'FR' ? FrPaperTitles[1] : EngPaperTitles[1] } Text={language === 'FR' ? FrPaperTexts[1] : EngPaperTexts[1] } />
                <InfoBlock Icon={icon_faq_3} Title={language === 'FR' ? FrPaperTitles[2] : EngPaperTitles[2] } Text={language === 'FR' ? FrPaperTexts[2] : EngPaperTexts[2] } />
                <InfoBlock Icon={icon_faq_4} Title={language === 'FR' ? FrPaperTitles[3] : EngPaperTitles[3] } Text={language === 'FR' ? FrPaperTexts[3] : EngPaperTexts[3] } />
                <InfoBlock Icon={icon_faq_5} Title={language === 'FR' ? FrPaperTitles[4] : EngPaperTitles[4] } Text={language === 'FR' ? FrPaperTexts[4] : EngPaperTexts[4] } />
                <InfoBlock Icon={icon_faq_6} Title={language === 'FR' ? FrPaperTitles[5] : EngPaperTitles[5] } Text={language === 'FR' ? FrPaperTexts[5] : EngPaperTexts[5] } />
                <InfoBlock Icon={icon_faq_7} Title={language === 'FR' ? FrPaperTitles[6] : EngPaperTitles[6] } Text={language === 'FR' ? FrPaperTexts[6] : EngPaperTexts[6] } />
                <InfoBlock Icon={icon_faq_8} Title={language === 'FR' ? FrPaperTitles[7] : EngPaperTitles[7] } Text={language === 'FR' ? FrPaperTexts[7] : EngPaperTexts[7] } />
                <InfoBlock Icon={icon_faq_9} Title={language === 'FR' ? FrPaperTitles[8] : EngPaperTitles[8] } Text={language === 'FR' ? FrPaperTexts[8] : EngPaperTexts[8] } />
                <InfoBlock Icon={icon_faq_10} Title={language === 'FR' ? FrPaperTitles[9] : EngPaperTitles[9] } Text={language === 'FR' ? FrPaperTexts[9] : EngPaperTexts[9] } />

                <div className='row align_link2'>
                        <button name="rgpd" className='link2'onClick={handleCookiesOverlay} >{language === 'FR' ? 'Afficher les informations RGPD' : 'Display RGPD information' }</button>
                        <button name="legal_mentions" className='link2' onClick={handleMentionsOverlay} >{language === 'FR' ? 'Mentions légales' : 'Legal Notice'}</button>
                </div>
                

            </div>


        </section>
    );
}



export default Infos;