import React from 'react'

const PartnerBlock = ({BigTitle, SmallTitle, Text, Button_text, Link, handleContactClick}) => {

    const handleClick = (e) =>{
        e.preventDefault()
        handleContactClick('Contact')
        window.location.href=Link
    }
  return (
      <div className='partner_paper'>
                        <div className='partner_title_paper'>
                                    <div className='semi_title text_center'>
                                        {BigTitle}
                                    </div>
                                    <div className='small_text text_center small_title_margin'>
                                        {SmallTitle}
                                    </div>
                                </div>

                                <div className='partner_paper_text_block'>
                                    <div className='partner_text'>
                                        {Text}
                                    </div>

                                    <div className='btn_section row'>
                                        <button className='partner_btn col-12'onClick={handleClick}>{Button_text}</button>
                                    </div>
                                </div>
    </div>
  )
}

export default PartnerBlock