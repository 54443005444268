import React from 'react';
import './Home.css';
import HomeFrImg from '../../images/icons_illus_accueil.svg'
import MobileHomeImg from '../../images/illus_accueil_mobile.svg'
import HomeEngImg from '../../images/illus_accueil_eng.svg'
import MobileHomeEngImg from '../../images/illus_accueil_eng_mobile.svg'
import OuilinkLogo from '../../images/icons_logo_ouilink.svg'
import { FrHomeTitle, FrHomeText, FrHomeButton } from '../../Languages/French.jsx';
import { EngHomeTitle, EngHomeText, EngHomeButton } from '../../Languages/English';
import Cookies from 'universal-cookie/es6';


const Home = ({language,handleCookiesOverlay,handleContainer, HomeRef, AnimationTrigger}) => {
    const cookies = new Cookies()
    if(cookies.get('cookiesParameters')==null){
        handleCookiesOverlay(true)
    }
    const handleClick = (e) =>{
        e.preventDefault()
        handleContainer('Contact')
        window.location.href='#Contact'
    }

    return (    
        <div className='flex_container slideIn1' id='Home' ref={HomeRef}>
            <div className='col-7'>

                <img src={OuilinkLogo} alt='home_icon' className='ouilink_icon '/>

                <div className='main_title'>
                    {language ==='FR' ? FrHomeTitle : EngHomeTitle }
                </div>

                <div className='row'>
                    <div className='col-10 divider' ref={AnimationTrigger} ></div>
                    <div className='col-2'></div>
                </div>

                <div className='semi_title'>
                    { language === 'FR' ? FrHomeText : EngHomeText }
                </div>

                <div className='mobile_home_img'>
                    <img src={ language === 'FR' ? MobileHomeImg : MobileHomeEngImg } alt='mobile_home_icon' className='mobile_home_icon '/>
                </div>

                <div className='btn_section row'>
                    <button className='home_btn col-8'onClick={handleClick}>{ language === 'FR' ? FrHomeButton : EngHomeButton }</button>
                </div>

            </div>
            <div className='col-5'>
                <img src={ language === 'FR' ? HomeFrImg : HomeEngImg } alt='home_icon' className='home_icon '/>
            </div>
        </div>
    );
}

export default Home;