import React from 'react'

const RestorerBlock = ({BigTitle, SmallTitle, TextTitle, List, Text, ButtonText, ShowTitle, Link, handleContactClick, Message, Reason, id}) => {

    const handleClick = (e) =>{
        e.preventDefault()
        handleContactClick('Contact')
        Message.reason=Reason
        window.location.href=Link
    }

  return (
    <div className='partner_paper'>
                                <div className='partner_title_paper'>
                                    <div className='semi_title text_center'>
                                        {BigTitle}
                                    </div>
                                    <div className='small_text text_center small_title_margin'>
                                        {SmallTitle}
                                    </div>
                                </div>

                                <div className='partner_paper_text_block'>
                                    <div className='partner_text'>
                                        {ShowTitle && <div className='partner_text_title'>{TextTitle}</div>}
                                        <ul className='partner_list'>
                                            {List.map((Item,index) => <li key={index} className='partner_list_items'>{Item}</li>)}
                                        </ul>
                                        <div className='text_divider'></div>
                                            {Text}
                                    </div>

                                    <div className='btn_section row restorer_btn_section'>
                                        <button className='partner_btn col-12' id={id} onClick={handleClick}>{ButtonText}</button>
                                    </div>
                                </div>
                            </div>
  )
}

export default RestorerBlock